import React, {useState} from 'react';
import { FeaturedItemContainer } from './Featured.styles';
import OptimizedImage from '../../utils/OptimizedImage';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Icon from '../../styles/atoms/icons';
import PersistLink from '../PersistLink/PersistLink';

export default function FeaturedItem({
  excerpt,
  tags,
  title,
  featuredImage: { gatsbyImageData },
  callToAction,
}) {
  const [hover, setHover] = useState(false);
  return (
    <FeaturedItemContainer onHover={hover}>
      {gatsbyImageData && (
        <PersistLink to={callToAction.url}>
          <OptimizedImage image={gatsbyImageData} alt={title} title={title} />
        </PersistLink>
      )}
      <div className="right-container">
        <div className="text-container">
          <span className='tags'>{tags[0].name}</span>
          <h3>
            <PersistLink
              to={callToAction.url}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}>
              {title}
            </PersistLink>
          </h3>
          <p>{excerpt}</p>
        </div>
        <CallToAction
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          value={callToAction.label}
          url={callToAction.url}
          variant="linkColor"
          icon={<Icon id="FiChevronRight" />}
        />
      </div>
    </FeaturedItemContainer>
  );
}